import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import userDarkMode from "../components/useDarkMode";
import DarkModeOn from "./DarkModeOn";
import DarkModeOff from "./DarkModeOff";
import Pdf from "../components/Software-Dev-Rommel-Medina.pdf";

export default function Navbar1() {
  const [isOpen, setIsOpen] = useState(false);
  const [colorTheme, setTheme] = userDarkMode()
  return (
    <header className="md:sticky top-0 z-50">    
      <div>
        <nav className="bg-gray-50 dark:bg-indigo-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="#home">
                    <span className="font-black text-2xl px-3 py-4 text-gray-900 dark:text-indigo-100 hover:text-gray-600 dark:hover:text-white">RM</span>
                  </a>
                </div>
                <div className="hidden md:block">
                  <div className="ml-20 flex items-baseline space-x-4">
                    <a href="#experience" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      Experience
                    </a>
                    <a href="#skills" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      Skills
                    </a>
                    <a href="#projects" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      Projects
                    </a>
                    <a href="#about" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      About
                    </a>
                    <a href={Pdf} target = "_blank" rel="noopener noreferrer" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      Resume
                    </a>
                    <a href="#contact" className="text-gray-900 dark:text-white border border-transparent hover:border-gray-400 hover:border-1 px-3 py-2 rounded-md text-sm font-medium">
                      Contact Me
                    </a>
                    <div className="mx-auto"></div>
                    <span onClick={() => setTheme(colorTheme)} className="cursor-pointer text-blue-400 dark:text-yellow-300 px-4 py-2 rounded-md text-2xl font-medium my-auto align-middle"> 
                      {colorTheme === "light" ? <DarkModeOn /> : <DarkModeOff />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-purple-800 dark:bg-indigo-50 inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:hover:text-indigo-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-400 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <a href="#about" className="text-gray-900 dark:text-white hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Rommel Medina
                  </a>
                  <a href="#experience" className="text-gray-900 dark:text-gray-300 hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Experience
                  </a>
                  <a href="#projects" className="text-gray-900 dark:text-gray-300 hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Projects
                  </a>
                  <a href="#skills" className="text-gray-900 dark:text-gray-300 hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Skills
                  </a>
                  <a href="#contact" className="text-gray-900 dark:text-gray-300 hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Contact Me
                  </a>
                  <span onClick={() => setTheme(colorTheme)} className="text-gray-900 dark:text-gray-300 hover:bg-purple-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"> 
                      {colorTheme === "light" ? 
                        "Light Theme"
                        : 
                        "Dark Theme"
                      }
                    </span>                  
                </div>
              </div>
            )}
          </Transition>
        </nav>
      </div>
    </header>
  );
}