import { BriefcaseIcon } from "@heroicons/react/solid";
import React from "react";

export default function Experience() {
  return (
    <section id="experience">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <BriefcaseIcon className="w-10 inline-block mb-4 text-pink-400 dark:text-indigo-50" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-gray-900 dark:text-white mb-4">
            Experience
          </h1>
          <p className="text-base leading-relaxed text-gray-700 dark:text-white xl:w-2/4 lg:w-3/4 mx-auto">
            My journey as a Software Developer from my recent work experience up to when I earned my bachelor's degree.
          </p>
        </div>
        <div class="container">
          <div
            class="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50"
          >
            {/* left */}
            <div class="flex flex-row-reverse md:contents">
              <div class="bg-indigo-50 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md border border-blue-400">
                <h5 className="mb-3 font-bold text-gray-900">Sep 2021 - Oct 2021</h5>
                <h3 className="text-gray-700">Joined Ensemble as a</h3>
                <h3 className="font-bold text-gray-900 text-xl">Software Developer</h3>
                <h5 className="font-bold text-gray-800">Vancouver, BC, Canada</h5>                
                <p className="mb-3 text-sm leading-snug tracking-wide text-gray-600 text-opacity-100">
                    Developed AEM (Adobe Experience Manager) components as enterprise Content Management Solution using HTL or Sightly as template engine, Apache Sling as the web framework and Apache Jackrabbit as the backend.
                </p>
                <a href="https://www.ensemble.com/" className="text-sm font-semibold text-gray-700">www.ensemble.com</a>
              </div>
              <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-400 shadow"
                ></div>
              </div>
            </div>

            {/* right */}
            <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-700 shadow"
                ></div>
              </div>
              <div class="bg-indigo-50 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md border border-blue-700">
                <h5 className="mb-3 font-bold text-gray-900">Mar 2019 - Jul 2021</h5>
                <h3 className="text-gray-700">Transitioned from a Programmer / Analyst to a</h3>
                <h3 className="font-bold text-gray-900 text-xl">Systems Analyst</h3>
                <h5 className="font-bold text-gray-800">Makati City, Philippines</h5>                
                <ul className="mb-3 list-disc px-6 text-sm font-medium leading-snug tracking-wide text-gray-600 text-opacity-100">
                    <li>Work in collaboration with Business Units and other IT teams or groups in requirements gathering and analysis to ensure all important aspects of the project are on the right path</li>
                    <li>Development of REST APIs to integrate the Business Online Banking Web Application to the Bank's Core Banking System</li>
                    <li>Design and Development of code based on the gathered requirements</li>
                    <li>Maintenance and Support of applications, including the creation of Technical Documentations</li>
                </ul>
                <a href="https://www.bdo.com.ph" className="text-sm font-semibold text-gray-700">www.bdo.com.ph</a>
              </div>
            </div>

            {/* right */}
            <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-700 shadow"></div>
              </div>
              <div class="bg-indigo-50 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md border border-blue-700">
                <h5 className="mb-3 font-bold text-gray-900">Aug 2012 - Mar 2019</h5>
                <h3 className="text-gray-700">Joined BDO Unibank Inc. as a</h3>
                <h3 className="font-bold text-gray-900 text-xl">Programmer / Systems Analyst</h3>
                <h5 className="font-bold text-gray-800">Makati City, Philippines</h5>                
                <ul className="mb-3 list-disc px-6 text-sm font-medium leading-snug tracking-wide text-gray-600 text-opacity-100">
                    <li>Developed standalone Java applications to integrate the Business Online Banking web application to the Bank's Core Banking System</li>
                    <li>Designed and Developed applications based on the gathered requirements</li>
                    <li>Developed enhancements and did maintenance and support of legacy applications, including the creation of Technical Documentations</li>
                </ul>                        
                <a href="https://www.bdo.com.ph" className="text-sm font-semibold text-gray-700">www.bdo.com.ph</a>
              </div>
            </div>

            {/* left */}
            <div class="flex flex-row-reverse md:contents">
              <div class="bg-indigo-50 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md border border-blue-600">
                <h5 className="mb-3 font-bold text-gray-900">Jun 2008 - Jul 2012</h5>
                <h3 className="text-gray-700">Joined GXS Philippines Inc. (now Opentext) as a</h3>
                <h3 className="font-bold text-gray-900 text-xl">Software Engineer</h3>
                <h5 className="font-bold text-gray-800">Makati City, Philippines</h5>                
                <ul className="mb-3 list-disc px-6 text-sm font-medium leading-snug tracking-wide text-gray-600 text-opacity-100">
                    <li>Worked with other developers to build technical solutions & complete projects to budgetary and quality requirements</li>
                    <li>Participated in design, code, and peer-reviews</li>
                    <li>Resolved technical issues through debugging, research, and investigation</li>
                </ul>                        
                <a href="https://www.opentext.com/" className="text-sm font-semibold text-gray-700">www.gxs.com.ph</a>
              </div>
              <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div class="w-6 h-6 absolute top-1/2 -mt-3 bg-blue-600 rounded-full shadow"></div>
              </div>
            </div>

            {/* right */}
            <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div class="w-6 h-6 absolute top-1/2 -mt-3 bg-yellow-400 rounded-full shadow"></div>               
              </div>
              <div class="bg-indigo-50 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md border border-yellow-400">
                <h5 className="mb-3 font-bold text-gray-900">Apr 2008 - May 2008</h5>
                <h3 className="text-gray-700">Completed Java Coding Boot Camp at</h3>
                <h3 className="font-bold text-gray-900 text-xl">Asia Pacific College - IBM Advanced Career Education</h3>
                <h5 className="font-bold text-gray-800">Magallanes, Philippines</h5>                
                <p className="text-sm leading-snug tracking-wide text-gray-600 text-opacity-100">
                    were I learned about Java Application Development (J2SE, J2EE, OOAD, UML)
                </p>
              </div>
            </div>

            {/* left */}
            <div class="flex flex-row-reverse md:contents">
              <div class="bg-indigo-50 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md border border-red-900">
              <h5 className="mb-3 font-bold text-gray-900">Jun 2004 - Mar 2008</h5>
                <h3 className="text-gray-800">Earned my BS in Computer Science degree at</h3>
                <h3 className="font-bold text-gray-900 text-xl">Polytechnic University of the Philippines</h3>
                <h5 className="font-bold text-gray-600">Sto. Tomas, Batangas, Philippines</h5>            
              </div>
              <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-300 dark:bg-indigo-50 pointer-events-none"></div>
                </div>
                <div class="w-6 h-6 absolute top-1/2 -mt-3 bg-red-900 rounded-full shadow"></div>
              </div>
            </div>
          
          </div>
        </div>

      </div>        
    </section>
  );
}