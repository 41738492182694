export const projects = [
    {
      title: "ACME - Student Management System",
      subtitle: "Spring Boot, React, Docker, AWS",
      description:
        "This project is about creating a full stack CRUD application with CI/CD using Github Actions and features the use of the most popular cloud platform today, AWS. (work in progress)",
      image: "./acme.png",
      link: "http://fullstackspringbootreact-env.eba-mrbwn2uj.ca-central-1.elasticbeanstalk.com/",
    },
    {
      title: "Covid Watch",
      subtitle: "Spring Boot, Thymeleaf, Bootstrap and Open Disease API",
      description:
        "A Java web application for getting the users updated on the current rate of COVID-19 cases and the number of active cases reported worldwide. The application uses data from a Third Party API (Open Disease Data API) for reliable global disease information.",
      image: "./covid-watch.png",
      link: "https://github.com/medinar/covid-watch",
    },
    {
      title: "Rommel Medina - Portfolio",
      subtitle: "React and Tailwind CSS",
      description:
        "This project shows information about me such as my work experience, personal projects and ways to contact me.",
      image: "./my-portfolio.png",
      link: "#",
    },
    {
      title: "ACME - Student Management System",
      subtitle: "Spring Boot, React, Docker, AWS",
      description:
        "This project is about creating a full stack CRUD application with CI/CD using Github Actions and features the use of the most popular cloud platform today, AWS. (work in progress)",
      image: "./pexels-rodnae-productions-7648050.jpeg",
      link: "http://fullstackspringbootreact-env.eba-mrbwn2uj.ca-central-1.elasticbeanstalk.com/",
    }
  ];

export const skills = [
  {
    category: "Industry Knowledge",
    skills: [
      "Software Development", "Information Technology", "Front-end Development", "Web Applications", "Troubleshooting", 
      "OOP", "Scalability", "Test Driven Design", "Behavior Driven Design", "Event Driven Design", "Responsive Design"
    ]
  },
  {
    category: "Tools & Technologies",
    skills: [
      "Core Java", "J2EE", "REST APIs", "Spring Framework", "Spring Boot", 
      "Oracle", "SQL", "MySQL", "PostgreSQL", "Git", "GitHub",  
      "RESTful Webservices", "Representational State Transfer (REST)", "Postman", "Swagger", "Maven",
      "HTML5", "CSS3", "Javascript", "Tailwind CSS", "Bootstrap", "Ant Design",
      "JUnit", "Mockito", "AssertJ", "SonarLint",
      "React", "Docker", "AWS"
    ]
  },
  {
    category: "Interpersonal Skills",
    skills: [
      "Customer Service", "Problem Solving" 
    ]
  },
  {
    category: "Other Skills",
    skills: [
      "Microservices", "Gitlab", "Back-End Web Development", "API Development", "WebSocket"
    ]
  }
];

export const testimonials = [
    {
        quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a hendrerit nunc. Nam tincidunt tincidunt ligula eu lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae ligula ac purus convallis sagittis at sed diam. Maecenas maximus tincidunt viverra. Fusce consectetur rhoncus neque vitae imperdiet. Nullam consequat semper ipsum vel gravida. Nulla molestie erat sed ligula consectetur, a laoreet dui semper.",
        name: "Bruno Salvador",
        image: "./pexels-bruno-salvadori-2269872.jpeg",
        company: "ACME"
    },
    {
        quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a hendrerit nunc. Nam tincidunt tincidunt ligula eu lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae ligula ac purus convallis sagittis at sed diam. Maecenas maximus tincidunt viverra. Fusce consectetur rhoncus neque vitae imperdiet. Nullam consequat semper ipsum vel gravida. Nulla molestie erat sed ligula consectetur, a laoreet dui semper.",
        name: "Kloe Chan",
        image: "./kloechan.jpeg",
        company: "ABC"
    },
    {
        quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a hendrerit nunc. Nam tincidunt tincidunt ligula eu lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae ligula ac purus convallis sagittis at sed diam. Maecenas maximus tincidunt viverra. Fusce consectetur rhoncus neque vitae imperdiet. Nullam consequat semper ipsum vel gravida. Nulla molestie erat sed ligula consectetur, a laoreet dui semper.",
        name: "Hudson Marques",
        image: "./pexels-hudson-marques-3435318.jpeg",
        company: "CBA"
    },
    {
        quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a hendrerit nunc. Nam tincidunt tincidunt ligula eu lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae ligula ac purus convallis sagittis at sed diam. Maecenas maximus tincidunt viverra. Fusce consectetur rhoncus neque vitae imperdiet. Nullam consequat semper ipsum vel gravida. Nulla molestie erat sed ligula consectetur, a laoreet dui semper.",
        name: "Sam Vazhnik",
        image: "./pexels-vazhnik-7562076.jpeg",
        company: "FB"
    }        
];