import React from "react";

export default function Home() {
  return (
    <section id="home">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-800 dark:text-white">
            Hi there,
            <br className="hidden lg:inline-block" /> I'm Rommel Medina.
          </h1>
          <p className="mb-8 leading-relaxed text-gray-800 dark:text-white">
            I'm a Software Developer based in British Columbia, Canada 🇨🇦, I have a fervent interest in designing high-performing, sturdy, and adaptable software solutions. Whether you're an employer searching for a Software Developer to support your company or have fascinating projects to collaborate on, let's get in touch.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white dark:text-purple-800 bg-purple-800 dark:bg-white border-0 py-2 px-6 focus:outline-none hover:text-white hover:bg-purple-400 dark:hover:bg-purple-800 dark:hover:text-white rounded text-lg">
              Let's Talk
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-purple-800 dark:text-white bg-transparent border border-purple-400 border-1 py-2 px-6 focus:outline-none hover:text-white hover:bg-purple-800 dark:hover:bg-white dark:hover:text-indigo-900 rounded text-lg">
              Check my work
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./hero.png"
          />
        </div>
      </div>
    </section>
  );
}
