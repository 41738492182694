import React from "react";

export default function DarkModeOn() {
  return (
    // <!-- Toggled switch -->
    <div className="flex justify-center items-center">
      <span className="">
          <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
      </span>
      {/* <!-- Switch Container --> */}
      <div className="w-10 h-5 flex items-center rounded-full mx-1 px-0 bg-gray-600">
          {/* // <!-- Switch --> */}
          <div className="bg-white w-4 h-4 rounded-full shadow-md transform translate-x-5"></div>
      </div>
      <span className="">
          <svg className="h-5 w-5 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
          </svg>
      </span>
    </div>
  );
}